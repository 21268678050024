import Plausible from "plausible-tracker";
import {useEffect} from "react";
import "./App.scss";
import {AboutSection} from "./components/AboutSection";
import {ContactSection} from "./components/ContactSection";
import {ExperienceSection} from "./components/ExperienceSection";
import {FAQSection} from "./components/FAQSection";
import {Footer} from "./components/Footer";
import {HeroSection} from "./components/HeroSection";
import {Navbar} from "./components/Navbar";
import {SkillsSection} from "./components/SkillsSection";
import {TestimonialsSection} from "./components/TestimonialsSection";

function App() {
    const plausible = Plausible({
        domain: "jarrodsavard.com",
        trackLocalhost: false,
    });

    useEffect(() => {
        plausible.enableAutoPageviews();
    }, []);

    return (
        <div className="App overflow-x-hidden bg-slate-900 text-white">
            <Navbar />
            <main>
                <HeroSection />
                <AboutSection />
                <ExperienceSection />
                <SkillsSection />
                <TestimonialsSection />
                <FAQSection />
                <ContactSection />
                <Footer />
            </main>
        </div>
    );
}

export default App;
