import {AnimatePresence, motion} from "framer-motion";
import {FaEnvelope, FaGithub, FaLinkedin, FaPhone} from "react-icons/fa";
import {FiAlertCircle} from "react-icons/fi";

export const Modal: React.FC<{isOpen: boolean; setIsOpen: any}> = ({isOpen, setIsOpen}) => {
    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    onClick={() => setIsOpen(false)}
                    className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center overflow-hidden cursor-pointer">
                    <motion.div
                        initial={{scale: 0}}
                        animate={{scale: 1}}
                        exit={{scale: 0}}
                        onClick={(e) => e.stopPropagation()}
                        className="bg-gradient-to-br from-violet-600 to-indigo-600 text-white p-6 rounded-lg w-full max-w-lg shadow-xl cursor-default relative overflow-hidden">
                        <FiAlertCircle className="text-white/10 rotate-12 text-[250px] absolute z-0 -top-24 -left-24" />
                        <div className="relative z-10">
                            <h3 className="text-3xl font-bold text-center mb-2">Contact Me</h3>
                            <p className="text-center mb-6">Feel free to reach out through any of these channels:</p>

                            <div className="space-y-4 mb-6">
                                <div className="flex items-center space-x-4">
                                    <div className="bg-white/10 p-3 rounded-full">
                                        <FaEnvelope className="text-white text-xl" />
                                    </div>
                                    <div>
                                        <p className="text-sm text-white/70">Email</p>
                                        <a
                                            href="mailto:Jarrodsavard2x180@gmail.com"
                                            className="text-white hover:text-blue-200 transition-colors">
                                            Jarrodsavard2x180@gmail.com
                                        </a>
                                    </div>
                                </div>

                                <div className="flex items-center space-x-4">
                                    <div className="bg-white/10 p-3 rounded-full">
                                        <FaPhone className="text-white text-xl" />
                                    </div>
                                    <div>
                                        <p className="text-sm text-white/70">Phone</p>
                                        <a
                                            href="tel:+13109537628"
                                            className="text-white hover:text-blue-200 transition-colors">
                                            (310) 953-7628
                                        </a>
                                    </div>
                                </div>

                                <div className="flex items-center space-x-4">
                                    <div className="bg-white/10 p-3 rounded-full">
                                        <FaLinkedin className="text-white text-xl" />
                                    </div>
                                    <div>
                                        <p className="text-sm text-white/70">LinkedIn</p>
                                        <a
                                            href="https://www.linkedin.com/in/jarrod-savard"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-white hover:text-blue-200 transition-colors">
                                            linkedin.com/in/jarrod-savard
                                        </a>
                                    </div>
                                </div>

                                <div className="flex items-center space-x-4">
                                    <div className="bg-white/10 p-3 rounded-full">
                                        <FaGithub className="text-white text-xl" />
                                    </div>
                                    <div>
                                        <p className="text-sm text-white/70">GitHub</p>
                                        <a
                                            href="https://github.com/JarrodSavard"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-white hover:text-blue-200 transition-colors">
                                            github.com/JarrodSavard
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <button
                                onClick={() => setIsOpen(false)}
                                className="bg-white hover:bg-white/90 transition-opacity text-indigo-600 font-semibold w-full py-2 rounded">
                                Close
                            </button>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};
