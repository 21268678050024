import React from "react";
import {FaBrain, FaLaptopCode, FaRocket, FaUsers} from "react-icons/fa";
import {RevealAnimation} from "../../animations/RevealAnimation";

export const AboutSection = () => {
    return (
        <section
            id="about"
            className="w-full px-8 py-20 max-w-7xl mx-auto">
            <RevealAnimation>
                <h2 className="text-4xl font-bold mb-2 text-blue-400">About Me</h2>
                <div className="h-1 w-20 bg-blue-500 mb-8"></div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-start">
                    <div>
                        <p className="text-lg text-slate-300 mb-6">Startup-focused technical leader with 8+ years crafting innovative software solutions that drive business growth and secure investor funding. As a founding engineer across multiple ventures, I've architected systems that transformed early-stage concepts into market-ready products, most recently helping secure $2.5M in seed funding.</p>
                        <p className="text-lg text-slate-300 mb-6">My unique blend of full-stack engineering expertise, product vision, and AI integration capabilities enables me to build scalable applications while simultaneously shaping product strategy. Known for creating intuitive interfaces that users love, optimizing performance across complex tech stacks, and establishing engineering practices that scale with rapid growth.</p>
                        <p className="text-lg text-slate-300">I thrive in the dynamic environment of early-stage companies where technical decisions directly impact business outcomes.</p>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        <div className="bg-slate-800 p-6 rounded-lg border border-blue-800 hover:border-blue-400 transition-colors">
                            <FaLaptopCode className="text-blue-400 text-3xl mb-4" />
                            <h3 className="text-xl font-semibold mb-2 text-blue-100">Full-Stack Development</h3>
                            <p className="text-slate-300">Expertise in building end-to-end applications with modern frontend and backend technologies.</p>
                        </div>

                        <div className="bg-slate-800 p-6 rounded-lg border border-blue-800 hover:border-blue-400 transition-colors">
                            <FaBrain className="text-blue-400 text-3xl mb-4" />
                            <h3 className="text-xl font-semibold mb-2 text-blue-100">AI/ML Integration</h3>
                            <p className="text-slate-300">Creating intelligent applications with advanced natural language capabilities and data analysis.</p>
                        </div>

                        <div className="bg-slate-800 p-6 rounded-lg border border-blue-800 hover:border-blue-400 transition-colors">
                            <FaUsers className="text-blue-400 text-3xl mb-4" />
                            <h3 className="text-xl font-semibold mb-2 text-blue-100">Team Leadership</h3>
                            <p className="text-slate-300">Mentoring engineers and developing strong engineering cultures that scale with growth.</p>
                        </div>

                        <div className="bg-slate-800 p-6 rounded-lg border border-blue-800 hover:border-blue-400 transition-colors">
                            <FaRocket className="text-blue-400 text-3xl mb-4" />
                            <h3 className="text-xl font-semibold mb-2 text-blue-100">Product Vision</h3>
                            <p className="text-slate-300">Transforming early-stage concepts into market-ready products that secure investor funding.</p>
                        </div>
                    </div>
                </div>
            </RevealAnimation>
        </section>
    );
};
