import {motion} from "framer-motion";
import React from "react";
import {FaEnvelope, FaGithub, FaHiking, FaLaptopCode, FaLinkedin, FaMountain} from "react-icons/fa";

export const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-slate-900 border-t border-slate-800 pt-16 pb-8 text-slate-300">
            <div className="max-w-7xl mx-auto px-8">
                <div className="grid grid-cols-1 md:grid-cols-12 gap-8 mb-12">
                    {/* Logo & Info */}
                    <div className="md:col-span-5">
                        <h2 className="text-3xl font-bold text-white mb-4">Jarrod Savard</h2>
                        <p className="text-slate-400 mb-6 max-w-md">Founding Engineer | Product-Focused Technical Leader | AI/ML Integration Specialist</p>
                        <div className="flex space-x-4">
                            <motion.a
                                href="https://github.com/JarrodSavard"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-slate-800 p-3 rounded-full hover:bg-slate-700 transition-colors"
                                whileHover={{y: -3}}
                                aria-label="GitHub">
                                <FaGithub
                                    className="text-blue-400"
                                    size={18}
                                />
                            </motion.a>

                            <motion.a
                                href="https://www.linkedin.com/in/jarrod-savard"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="bg-slate-800 p-3 rounded-full hover:bg-slate-700 transition-colors"
                                whileHover={{y: -3}}
                                aria-label="LinkedIn">
                                <FaLinkedin
                                    className="text-blue-400"
                                    size={18}
                                />
                            </motion.a>

                            <motion.a
                                href="mailto:Jarrodsavard2x180@gmail.com"
                                className="bg-slate-800 p-3 rounded-full hover:bg-slate-700 transition-colors"
                                whileHover={{y: -3}}
                                aria-label="Email">
                                <FaEnvelope
                                    className="text-blue-400"
                                    size={18}
                                />
                            </motion.a>
                        </div>
                    </div>

                    {/* Quick Links */}
                    <div className="md:col-span-3">
                        <h3 className="text-lg font-semibold text-white mb-4">Quick Links</h3>
                        <ul className="space-y-2">
                            <li>
                                <a
                                    href="#about"
                                    className="hover:text-blue-300 transition-colors">
                                    About Me
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#experience"
                                    className="hover:text-blue-300 transition-colors">
                                    Experience
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#skills"
                                    className="hover:text-blue-300 transition-colors">
                                    Skills
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#projects"
                                    className="hover:text-blue-300 transition-colors">
                                    Projects
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* Interests */}
                    <div className="md:col-span-4">
                        <h3 className="text-lg font-semibold text-white mb-4">Interests</h3>
                        <div className="grid grid-cols-2 gap-3">
                            <div className="flex items-center gap-2 bg-slate-800 p-3 rounded-lg">
                                <FaLaptopCode className="text-blue-400" />
                                <span>Emerging Tech</span>
                            </div>
                            <div className="flex items-center gap-2 bg-slate-800 p-3 rounded-lg">
                                <FaHiking className="text-blue-400" />
                                <span>Hiking</span>
                            </div>
                            <div className="flex items-center gap-2 bg-slate-800 p-3 rounded-lg">
                                <svg
                                    className="w-4 h-4 text-blue-400"
                                    viewBox="0 0 24 24"
                                    fill="currentColor">
                                    <path d="M20.8889 7.55556C20.8889 10.7222 18.9167 13.4444 16.1111 14.6944V23L12 20.5L7.88889 23V14.6944C5.08333 13.4444 3.11111 10.7222 3.11111 7.55556C3.11111 3.38889 7.05556 0 12 0C16.9444 0 20.8889 3.38889 20.8889 7.55556Z" />
                                </svg>
                                <span>Surfing</span>
                            </div>
                            <div className="flex items-center gap-2 bg-slate-800 p-3 rounded-lg">
                                <FaMountain className="text-blue-400" />
                                <span>Rock Climbing</span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Copyright */}
                <div className="border-t border-slate-800 pt-8 text-center">
                    <p className="text-sm text-slate-500">&copy; {currentYear} Jarrod Savard. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
};
