import {motion} from "framer-motion";
import React, {useState} from "react";
import {RevealAnimation} from "../../animations/RevealAnimation";

interface Experience {
    id: number;
    company: string;
    role: string;
    period: string;
    description: string[];
    highlights: string[];
}

export const ExperienceSection = () => {
    const [activeTab, setActiveTab] = useState(0);

    const experiences: Experience[] = [
        {
            id: 1,
            company: "Pinnacle",
            role: "Founding Senior FullStack Engineer",
            period: "Dec 2023 – Present",
            description: ["Played foundational role in securing $2.5M in pre-seed to seed funding through technical leadership and product vision.", "Led strategic product pivot by identifying market needs and implementing technical solutions that aligned with investor expectations.", "Architected high-performance full-stack systems using React, TailwindCSS, TanStack Query, Zustand, Express.js, and AI integration with Flask (Python)."],
            highlights: ["Created complete chat services and multiple user flows with AI capabilities", "Designed comprehensive mockups and interactive prototypes in Figma for early demos", "Established company engineering practices including sprint rituals and CI/CD optimization"],
        },
        {
            id: 3,
            company: "Kepler Saving",
            role: "Senior Founding Engineer",
            period: "June 2023 – Oct 2023",
            description: ["Led frontend development as a founding engineer, building the core application with ReactJS and TypeScript in coordination with design and backend teams.", "Architected a robust foundational framework that enhanced project efficiency and streamlined collaborative development processes.", "Developed custom data visualization components using D3.js and translated complex Figma designs into beautiful UI components."],
            highlights: ["Led strategic technical meetings to evaluate technology stack choices", "Optimized pre-launch application performance for system responsiveness", "Earned recognition from the CTO for technical excellence and problem-solving"],
        },
        {
            id: 4,
            company: "Chingu Voyages",
            role: "Frontend Software Engineer (Volunteer)",
            period: "April 2023 – June 2023",
            description: ["Led a team of 5 developers in an agile project, mentoring junior developers and delivering a full-stack web application.", "Designed user interfaces in Figma, guiding learners through user-centric and aesthetic UI design principles.", "Implemented and taught Object-Oriented Programming concepts, testing methodologies, and React best practices."],
            highlights: ["Served as technical mentor for team members", "Built team capabilities through hands-on guidance in React", "Created an environment balancing project delivery with learning opportunities"],
        },
        {
            id: 5,
            company: "Market Caviar",
            role: "Founder & Founding Principal Engineer",
            period: "April 2022 – April 2023",
            description: ["Founded and managed a fintech startup, leading a team of 3 developers and securing data partnerships.", "Implemented machine learning, algorithmic analysis, and live graphing techniques to provide users with informed trading decisions.", "Built comprehensive tech stack including Next.js, AWS, Stripe, Redis, and NoSQL databases."],
            highlights: ["Deployed and maintained secure Linux server running NGINX", "Designed user interfaces with Figma for a sophisticated fintech platform", "Engineered scalable web applications facilitating seamless financial transactions"],
        },
        {
            id: 6,
            company: "Fast",
            role: "Software Engineer II",
            period: "February 2020 - April 2022",
            description: ["Collaborated with designers to create intuitive, user-centered interfaces for e-commerce payment solutions.", "Optimized software performance using advanced techniques such as code profiling and caching strategies.", "Analyzed user feedback and platform analytics to identify and implement high-impact improvements."],
            highlights: ["Utilized GitHub for version control across distributed engineering teams", "Contributed to Fast's one-click checkout solutions used by numerous retailers", "Wrote clean, maintainable code with robust testing and debugging processes"],
        },
        {
            id: 7,
            company: "Self-Employed",
            role: "Freelance Software Engineer",
            period: "November 2017 - February 2020",
            description: ["Delivered custom web applications and solutions for diverse clients, focusing on responsive design and interactive user experiences.", "Specialized in frontend development using React.js, creating efficient and well-structured component-based architectures.", "Managed the full project lifecycle from requirements gathering and client consultation to deployment and ongoing maintenance."],
            highlights: ["Built and maintained client relationships while consistently delivering ahead of deadlines", "Developed a portfolio of successful projects across e-commerce, healthcare, and education sectors", "Established efficient development workflows that maximized productivity and code quality"],
        },
    ];

    return (
        <section
            id="experience"
            className="w-full px-8 py-20 max-w-7xl mx-auto">
            <RevealAnimation>
                <h2 className="text-4xl font-bold mb-2 text-blue-400">Experience</h2>
                <div className="h-1 w-20 bg-blue-500 mb-12"></div>

                <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                    {/* Timeline navigation */}
                    <div className="lg:col-span-4">
                        <div className="sticky top-24">
                            {experiences.map((exp, index) => (
                                <div
                                    key={exp.id}
                                    className={`mb-6 cursor-pointer transition-all duration-300 border-l-4 pl-4 py-2 ${activeTab === index ? "border-blue-500 text-blue-400" : "border-slate-700 text-slate-400 hover:border-blue-300 hover:text-blue-200"}`}
                                    onClick={() => setActiveTab(index)}>
                                    <h3 className="font-semibold text-xl">{exp.company}</h3>
                                    <p className="text-sm">{exp.period}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Experience details */}
                    <div className="lg:col-span-8">
                        <motion.div
                            key={activeTab}
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.5}}
                            className="bg-slate-800 p-8 rounded-lg border border-slate-700">
                            <h3 className="text-2xl font-bold text-blue-300 mb-1">{experiences[activeTab].role}</h3>
                            <h4 className="text-xl font-medium text-blue-100 mb-2">{experiences[activeTab].company}</h4>
                            <p className="text-sm text-slate-400 mb-6">{experiences[activeTab].period}</p>

                            <div className="mb-6">
                                <h5 className="text-lg font-semibold text-blue-200 mb-3">Key Responsibilities</h5>
                                <ul className="space-y-2">
                                    {experiences[activeTab].description.map((item, i) => (
                                        <li
                                            key={i}
                                            className="flex items-start">
                                            <span className="text-blue-400 mr-2">•</span>
                                            <span className="text-slate-300">{item}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div>
                                <h5 className="text-lg font-semibold text-blue-200 mb-3">Highlights</h5>
                                <ul className="space-y-2">
                                    {experiences[activeTab].highlights.map((item, i) => (
                                        <li
                                            key={i}
                                            className="flex items-start">
                                            <span className="text-blue-400 mr-2">•</span>
                                            <span className="text-slate-300">{item}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </RevealAnimation>
        </section>
    );
};
