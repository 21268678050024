import {motion} from "framer-motion";
import React, {useState} from "react";
import {RevealAnimation} from "../../animations/RevealAnimation";

interface SkillCategory {
    id: number;
    name: string;
    skills: string[];
}

export const SkillsSection = () => {
    const [activeCategory, setActiveCategory] = useState(0);

    const skillCategories: SkillCategory[] = [
        {
            id: 1,
            name: "Frontend Development",
            skills: ["React.js", "Next.js", "TypeScript", "TailwindCSS", "Zustand", "TanStack Query", "Redux", "Context API", "HTML5", "CSS3", "Advanced Animations", "Responsive Design", "Accessibility", "Performance Optimization"],
        },
        {
            id: 2,
            name: "Design & User Experience",
            skills: ["Figma", "UI/UX Design", "Prototyping", "Wireframing", "User-Centered Design"],
        },
        {
            id: 3,
            name: "Backend & Infrastructure",
            skills: ["Node.js", "Express.js", "Python Flask", "GraphQL", "RESTful APIs", "AWS (EC2, S3, Lambda)", "Docker", "Linux Server Administration", "NGINX"],
        },
        {
            id: 4,
            name: "Databases & Data",
            skills: ["MongoDB", "PostgreSQL", "Redis", "D3.js Data Visualization", "Real-time Processing", "Caching Strategies"],
        },
        {
            id: 5,
            name: "AI & Machine Learning",
            skills: ["NLP", "GPT Integration", "TensorFlow", "scikit-learn", "pandas", "Matplotlib", "Custom Model Deployment", "Data Analysis", "Predictions", "Real-time Data Processing Pipelines"],
        },
        {
            id: 6,
            name: "Engineering Practices",
            skills: ["CI/CD Workflows (GitHub Actions)", "Automated Testing (Jest, Cypress)", "Code Quality Tools", "Agile Methodologies", "Sprint Planning", "Ticket Sizing", "Security Best Practices", "Authentication Systems", "Data Protection"],
        },
    ];

    return (
        <section
            id="skills"
            className="w-full px-8 py-20 max-w-7xl mx-auto">
            <RevealAnimation>
                <h2 className="text-4xl font-bold mb-2 text-blue-400">Skills & Expertise</h2>
                <div className="h-1 w-20 bg-blue-500 mb-12"></div>

                <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                    {/* Categories */}
                    <div className="lg:col-span-4">
                        <div className="flex flex-wrap lg:flex-col gap-3">
                            {skillCategories.map((category, index) => (
                                <button
                                    key={category.id}
                                    onClick={() => setActiveCategory(index)}
                                    className={`px-4 py-3 rounded-lg transition-all duration-300 text-left ${activeCategory === index ? "bg-blue-500 text-white font-medium" : "bg-slate-800 text-slate-300 hover:bg-slate-700"}`}>
                                    {category.name}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Skills */}
                    <div className="lg:col-span-8">
                        <motion.div
                            key={activeCategory}
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{duration: 0.4}}
                            className="bg-slate-800 p-8 rounded-lg border border-slate-700">
                            <h3 className="text-2xl font-bold text-blue-300 mb-6">{skillCategories[activeCategory].name}</h3>

                            <div className="flex flex-wrap gap-3">
                                {skillCategories[activeCategory].skills.map((skill, index) => (
                                    <motion.div
                                        key={index}
                                        initial={{opacity: 0, y: 20}}
                                        animate={{opacity: 1, y: 0}}
                                        transition={{duration: 0.3, delay: index * 0.05}}
                                        className="bg-slate-700 px-4 py-2 rounded-full text-blue-100">
                                        {skill}
                                    </motion.div>
                                ))}
                            </div>
                        </motion.div>
                    </div>
                </div>
            </RevealAnimation>
        </section>
    );
};
