import {motion} from "framer-motion";
import {useEffect, useState} from "react";
import {FaGithub, FaLinkedin} from "react-icons/fa";
import {MdEmail} from "react-icons/md";
import {RevealAnimation} from "../../animations/RevealAnimation";
import {Button} from "../Button";
import {Modal} from "../Modal";

export const HeroSection = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [typedText, setTypedText] = useState("");
    const fullText = "Building innovative solutions for the digital era.";
    const [typingComplete, setTypingComplete] = useState(false);

    // Typing animation effect
    useEffect(() => {
        if (typedText.length < fullText.length) {
            const timeout = setTimeout(() => {
                setTypedText(fullText.slice(0, typedText.length + 1));
            }, 70);
            return () => clearTimeout(timeout);
        } else {
            setTypingComplete(true);
        }
    }, [typedText]);

    return (
        <section className="relative min-h-screen flex items-center justify-center w-full px-8 py-20 mx-auto overflow-hidden">
            {/* Animated background elements */}
            <div className="absolute inset-0 overflow-hidden ">
                <div className="absolute top-[-30%] right-[-10%] w-[80%] h-[80%] rounded-full bg-blue-700/10 blur-3xl animate-pulse"></div>
                <div
                    className="absolute bottom-[-20%] left-[-10%] w-[70%] h-[70%] rounded-full bg-indigo-700/10 blur-3xl animate-pulse"
                    style={{animationDelay: "2s", animationDuration: "6s"}}></div>
                <div
                    className="absolute top-[40%] left-[30%] w-[40%] h-[40%] rounded-full bg-purple-700/10 blur-3xl animate-pulse"
                    style={{animationDelay: "3s", animationDuration: "8s"}}></div>

                {/* Grid pattern overlay */}
                <div className="absolute inset-0 bg-grid-pattern opacity-10"></div>

                {/* Animated particles */}
                {[...Array(15)].map((_, i) => (
                    <motion.div
                        key={i}
                        className="absolute bg-blue-400 rounded-full"
                        style={{
                            width: Math.random() * 6 + 2 + "px",
                            height: Math.random() * 6 + 2 + "px",
                            top: Math.random() * 100 + "%",
                            left: Math.random() * 100 + "%",
                        }}
                        animate={{
                            y: [0, Math.random() * -100 - 50],
                            opacity: [0, 0.5, 0],
                        }}
                        transition={{
                            duration: Math.random() * 10 + 10,
                            repeat: Infinity,
                            ease: "linear",
                        }}
                    />
                ))}
            </div>

            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
            />

            <div className="w-full relative z-10 ">
                <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-12 gap-8 items-center px-8">
                    <div className="lg:col-span-8 lg:pr-12">
                        <RevealAnimation>
                            <motion.div
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.8}}
                                className="text-center lg:text-left">
                                <div className="inline-block px-6 py-2 border border-blue-400 rounded-full text-blue-300 text-sm font-medium mb-6 backdrop-blur-sm bg-slate-900/30">Founding Engineer & Technical Leader</div>

                                <h1 className="text-6xl md:text-7xl xl:text-8xl font-bold mb-6">
                                    <span className="bg-gradient-to-r from-blue-400 via-blue-300 to-indigo-400 text-transparent bg-clip-text">Jarrod Savard</span>
                                </h1>

                                <h2 className="text-2xl md:text-3xl font-medium text-blue-50 mb-4">Founding Engineer | Product-Focused Technical Leader | AI/ML Integration Specialist</h2>

                                <div className="h-12 mb-8">
                                    <motion.p
                                        className="text-xl font-light text-slate-300 font-mono"
                                        initial={{opacity: 0}}
                                        animate={{opacity: 1}}
                                        transition={{duration: 0.5, delay: 0.5}}>
                                        <span>{typedText}</span>
                                        {!typingComplete && <span className="animate-pulse">|</span>}
                                    </motion.p>
                                </div>

                                <p className="text-lg text-slate-300 mb-8 max-w-2xl mx-auto lg:mx-0">Startup-focused technical leader with 8+ years crafting innovative software solutions that drive business growth and secure investor funding. I build scalable applications while shaping product strategy.</p>

                                <div className="flex flex-wrap justify-center lg:justify-start gap-4 mb-12">
                                    <motion.a
                                        href="https://www.linkedin.com/in/jarrod-savard"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center gap-2 px-5 py-3 bg-slate-800/50 backdrop-blur-sm rounded-lg text-blue-300 hover:text-blue-100 hover:bg-slate-700/50 transition-all duration-300 hover:scale-105"
                                        whileHover={{y: -3}}>
                                        <FaLinkedin size={24} /> LinkedIn
                                    </motion.a>
                                    <motion.a
                                        href="https://github.com/JarrodSavard"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="flex items-center gap-2 px-5 py-3 bg-slate-800/50 backdrop-blur-sm rounded-lg text-blue-300 hover:text-blue-100 hover:bg-slate-700/50 transition-all duration-300 hover:scale-105"
                                        whileHover={{y: -3}}>
                                        <FaGithub size={24} /> GitHub
                                    </motion.a>
                                    <motion.a
                                        href="mailto:Jarrodsavard2x180@gmail.com"
                                        className="flex items-center gap-2 px-5 py-3 bg-slate-800/50 backdrop-blur-sm rounded-lg text-blue-300 hover:text-blue-100 hover:bg-slate-700/50 transition-all duration-300 hover:scale-105"
                                        whileHover={{y: -3}}>
                                        <MdEmail size={24} /> Email
                                    </motion.a>
                                </div>

                                <div className="flex gap-6 flex-wrap justify-center lg:justify-start">
                                    <motion.div
                                        whileHover={{scale: 1.05}}
                                        whileTap={{scale: 0.95}}>
                                        <Button
                                            onClick={() => {
                                                document.getElementById("about")?.scrollIntoView({behavior: "smooth"});
                                            }}
                                            text="About Me"
                                        />
                                    </motion.div>
                                    <motion.div
                                        whileHover={{scale: 1.05}}
                                        whileTap={{scale: 0.95}}>
                                        <Button
                                            onClick={() => {
                                                document.getElementById("experience")?.scrollIntoView({behavior: "smooth"});
                                            }}
                                            text="Experience"
                                        />
                                    </motion.div>
                                    <motion.div
                                        whileHover={{scale: 1.05}}
                                        whileTap={{scale: 0.95}}>
                                        <Button
                                            onClick={() => setIsOpen(true)}
                                            text="Contact Me"
                                        />
                                    </motion.div>
                                </div>
                            </motion.div>
                        </RevealAnimation>
                    </div>

                    <div className="lg:col-span-4 mt-12 lg:mt-0 hidden lg:block">
                        <motion.div
                            initial={{opacity: 0}}
                            animate={{opacity: 1}}
                            transition={{duration: 1, delay: 0.5}}
                            className="relative">
                            {/* Code snippet decoration */}
                            <div className="backdrop-blur-sm bg-slate-900/60 border border-slate-700 rounded-lg p-6 shadow-2xl">
                                <div className="flex items-center gap-2 mb-4">
                                    <div className="w-3 h-3 rounded-full bg-red-500"></div>
                                    <div className="w-3 h-3 rounded-full bg-yellow-500"></div>
                                    <div className="w-3 h-3 rounded-full bg-green-500"></div>
                                    <div className="text-xs text-slate-400 ml-2">portfolio.tsx</div>
                                </div>

                                <pre className="text-sm">
                                    <code className="font-mono">
                                        <div className="text-slate-400">// Meet Jarrod Savard</div>
                                        <div className="text-blue-400">const</div> <div className="text-green-400">skills</div> <div className="text-blue-300">=</div> <div className="text-orange-300">{"{"}</div>
                                        <div className="ml-4">
                                            <div className="text-blue-300">frontend:</div> <div className="text-green-300">["React", "TypeScript", "TailwindCSS"]</div>,
                                        </div>
                                        <div className="ml-4">
                                            <div className="text-blue-300">backend:</div> <div className="text-green-300">["Node.js", "Express", "Python"]</div>,
                                        </div>
                                        <div className="ml-4">
                                            <div className="text-blue-300">architecture:</div> <div className="text-green-300">["Microservices", "AWS", "CI/CD"]</div>,
                                        </div>
                                        <div className="ml-4">
                                            <div className="text-blue-300">leadership:</div> <div className="text-green-300">["Product Strategy", "Team Building"]</div>
                                        </div>
                                        <div className="text-orange-300">{"}"}</div>
                                        <div className="mt-2">
                                            <div className="text-blue-400">function</div> <div className="text-yellow-300">buildGreatSoftware</div>
                                            <div className="text-slate-300">()</div> <div className="text-orange-300">{"{"}</div>
                                            <div className="ml-4">
                                                <div className="text-blue-400">return</div> <div className="text-green-300">"Innovative Solutions"</div>;
                                            </div>
                                            <div className="text-orange-300">{"}"}</div>
                                        </div>
                                    </code>
                                </pre>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
};
