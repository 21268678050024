import {AnimatePresence, motion} from "framer-motion";
import React, {useEffect, useState} from "react";
import {FaQuoteLeft} from "react-icons/fa";
import {RevealAnimation} from "../../animations/RevealAnimation";

interface Testimonial {
    id: number;
    content: string;
    author: string;
    role: string;
}

export const TestimonialsSection = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const testimonials: Testimonial[] = [
        {
            id: 1,
            content: "I hired Jarrod to be one of the founding UI engineers to help build our product with ReactJS and TypeScript. Jarrod is an excellent engineer, he possesses the skills to build a beautiful and performant product. He consistently produced quality code that replicated Figma designs and added value to the team by communicating ways we could improve. I was impressed with his immense knowledge in current and emerging technologies, and his ability to problem solve.",
            author: "Amanda Fenn",
            role: "Director of Engineering, REGO Payment Architectures",
        },
        {
            id: 2,
            content: "Working alongside Jarrod has been a fantastic experience! As an engineer with proficiency in React and Figma, he brings a unique blend of technical and design skills to the table. During our project, he showcased a deep understanding of user-centric design principles, seamlessly integrating complex functionalities while ensuring a top-notch user experience.",
            author: "Natalia Yarysheva",
            role: "Software Developer",
        },
        {
            id: 3,
            content: "Really enjoyed working with Jarrod on our mutual software project! Jarrod is hyper competent at React, OOP, testing, and design. He's also a great person to collaborate with and is generous and willing to share his knowledge and expertise with his peers. Great developer to have on any software team!",
            author: "Alexander Da Costa",
            role: "Fullstack Web Developer",
        },
    ];

    // Auto-cycle through testimonials
    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        }, 8000);

        return () => clearTimeout(timer);
    }, [currentIndex, testimonials.length]);

    const handleDotClick = (index: number) => {
        setCurrentIndex(index);
    };

    return (
        <section
            id="testimonials"
            className="w-full px-8 py-20 max-w-7xl mx-auto">
            <RevealAnimation>
                <h2 className="text-4xl font-bold mb-2 text-blue-400">What Colleagues Say</h2>
                <div className="h-1 w-20 bg-blue-500 mb-12"></div>

                <div className="relative bg-slate-800 rounded-xl p-8 md:p-12 overflow-hidden">
                    {/* Background decoration */}
                    <div className="absolute top-0 left-0 opacity-5 text-9xl">
                        <FaQuoteLeft />
                    </div>

                    <div className="relative min-h-[350px] flex flex-col justify-between">
                        <AnimatePresence mode="wait">
                            <motion.div
                                key={currentIndex}
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                exit={{opacity: 0, y: -20}}
                                transition={{duration: 0.5}}
                                className="mb-8">
                                <p className="text-slate-300 text-lg italic mb-8 leading-relaxed max-h-[220px] overflow-y-auto">{testimonials[currentIndex].content}</p>

                                <div>
                                    <p className="text-blue-300 font-semibold text-xl">{testimonials[currentIndex].author}</p>
                                    <p className="text-slate-400">{testimonials[currentIndex].role}</p>
                                </div>
                            </motion.div>
                        </AnimatePresence>

                        {/* Navigation dots */}
                        <div className="flex justify-center gap-3 mt-6">
                            {testimonials.map((_, index) => (
                                <button
                                    key={index}
                                    onClick={() => handleDotClick(index)}
                                    className={`w-3 h-3 rounded-full transition-all duration-300 ${index === currentIndex ? "bg-blue-500 scale-125" : "bg-slate-600 hover:bg-slate-500"}`}
                                    aria-label={`View testimonial ${index + 1}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </RevealAnimation>
        </section>
    );
};
