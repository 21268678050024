import {motion} from "framer-motion";
import {useEffect, useState} from "react";
import {FaGithub, FaLinkedin} from "react-icons/fa";
import {HiOutlineMenuAlt3, HiOutlineX} from "react-icons/hi";
import {Modal} from "../Modal";

export const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    // Handle scroll event to change navbar style
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    const navLinks = [
        {name: "About", href: "#about"},
        {name: "Experience", href: "#experience"},
        {name: "Skills", href: "#skills"},
        {name: "FAQ", href: "#faq"},
        {name: "Testimonials", href: "#testimonials"},
    ];

    return (
        <>
            <motion.nav
                initial={{opacity: 0, y: -20}}
                animate={{opacity: 1, y: 0}}
                transition={{duration: 0.5}}
                className={`fixed top-0 w-full z-50 transition-all duration-300 ${scrolled ? "bg-slate-900/90 backdrop-blur-md shadow-lg py-3" : "bg-transparent py-5"}`}>
                <Modal
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />

                <div className="max-w-7xl mx-auto px-6 flex items-center justify-between">
                    {/* Logo */}
                    <a
                        href="#"
                        className="text-2xl font-bold text-white hover:text-blue-300 transition-colors">
                        Jarrod Savard
                    </a>

                    {/* Desktop Navigation */}
                    <div className="hidden md:flex items-center space-x-8">
                        <div className="flex items-center space-x-6">
                            {navLinks.map((link) => (
                                <a
                                    key={link.name}
                                    href={link.href}
                                    className="text-slate-300 hover:text-blue-300 transition-colors">
                                    {link.name}
                                </a>
                            ))}
                        </div>

                        <div className="flex items-center space-x-4">
                            <a
                                href="https://github.com/JarrodSavard"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-slate-300 hover:text-blue-300 transition-colors"
                                aria-label="GitHub">
                                <FaGithub size={22} />
                            </a>
                            <a
                                href="https://www.linkedin.com/in/jarrod-savard"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-slate-300 hover:text-blue-300 transition-colors"
                                aria-label="LinkedIn">
                                <FaLinkedin size={22} />
                            </a>
                        </div>
                    </div>

                    {/* Mobile Menu Button */}
                    <button
                        className="md:hidden text-white focus:outline-none"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        aria-label={mobileMenuOpen ? "Close menu" : "Open menu"}>
                        {mobileMenuOpen ? <HiOutlineX size={24} /> : <HiOutlineMenuAlt3 size={24} />}
                    </button>
                </div>
            </motion.nav>

            {/* Mobile Menu */}
            <motion.div
                initial={{x: "100%"}}
                animate={{x: mobileMenuOpen ? "0%" : "100%"}}
                transition={{duration: 0.3}}
                className="fixed top-0 right-0 h-screen w-[250px] bg-slate-900 z-50 shadow-xl md:hidden pt-20 px-6">
                <div className="flex flex-col space-y-6">
                    {navLinks.map((link) => (
                        <a
                            key={link.name}
                            href={link.href}
                            className="text-slate-300 hover:text-blue-300 transition-colors text-lg"
                            onClick={() => setMobileMenuOpen(false)}>
                            {link.name}
                        </a>
                    ))}
                    <button
                        onClick={() => {
                            setMobileMenuOpen(false);
                            setIsOpen(true);
                        }}
                        className="text-slate-300 hover:text-blue-300 transition-colors text-lg text-left">
                        Contact
                    </button>

                    <div className="pt-6 flex space-x-6">
                        <a
                            href="https://github.com/JarrodSavard"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-slate-300 hover:text-blue-300 transition-colors"
                            aria-label="GitHub">
                            <FaGithub size={22} />
                        </a>
                        <a
                            href="https://www.linkedin.com/in/jarrod-savard"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-slate-300 hover:text-blue-300 transition-colors"
                            aria-label="LinkedIn">
                            <FaLinkedin size={22} />
                        </a>
                    </div>
                </div>
            </motion.div>

            {/* Overlay for mobile menu */}
            {mobileMenuOpen && (
                <motion.div
                    initial={{opacity: 0}}
                    animate={{opacity: 1}}
                    exit={{opacity: 0}}
                    transition={{duration: 0.2}}
                    className="fixed inset-0 bg-black/60 z-40 md:hidden"
                    onClick={() => setMobileMenuOpen(false)}
                />
            )}
        </>
    );
};
