import {motion} from "framer-motion";
import React from "react";
import {FaEnvelope, FaGithub, FaLinkedin, FaPhone} from "react-icons/fa";
import {RevealAnimation} from "../../animations/RevealAnimation";

export const ContactSection = () => {
    return (
        <section
            id="contact"
            className="w-full px-8 py-20 max-w-7xl mx-auto">
            <RevealAnimation>
                <h2 className="text-4xl font-bold mb-2 text-blue-400">Get In Touch</h2>
                <div className="h-1 w-20 bg-blue-500 mb-12"></div>

                <div className="max-w-3xl mx-auto">
                    {/* Contact Information */}
                    <div>
                        <h3 className="text-2xl font-semibold text-blue-300 mb-6">Contact Information</h3>
                        <p className="text-slate-300 mb-8">I'm always interested in new opportunities. Whether you have a question or just want to say hi, I'll do my best to get back to you as soon as possible.</p>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <motion.div
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.3}}
                                className="flex items-center space-x-4">
                                <div className="bg-slate-800 p-4 rounded-full">
                                    <FaEnvelope className="text-blue-400 text-2xl" />
                                </div>
                                <div>
                                    <p className="text-sm text-slate-400">Email</p>
                                    <a
                                        href="mailto:Jarrodsavard2x180@gmail.com"
                                        className="text-blue-300 hover:text-blue-200 transition-colors">
                                        Jarrodsavard2x180@gmail.com
                                    </a>
                                </div>
                            </motion.div>

                            <motion.div
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.3, delay: 0.1}}
                                className="flex items-center space-x-4">
                                <div className="bg-slate-800 p-4 rounded-full">
                                    <FaPhone className="text-blue-400 text-2xl" />
                                </div>
                                <div>
                                    <p className="text-sm text-slate-400">Phone</p>
                                    <a
                                        href="tel:+13109537628"
                                        className="text-blue-300 hover:text-blue-200 transition-colors">
                                        (310) 953-7628
                                    </a>
                                </div>
                            </motion.div>

                            <motion.div
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.3, delay: 0.2}}
                                className="flex items-center space-x-4">
                                <div className="bg-slate-800 p-4 rounded-full">
                                    <FaLinkedin className="text-blue-400 text-2xl" />
                                </div>
                                <div>
                                    <p className="text-sm text-slate-400">LinkedIn</p>
                                    <a
                                        href="https://www.linkedin.com/in/jarrod-savard"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-300 hover:text-blue-200 transition-colors">
                                        linkedin.com/in/jarrod-savard
                                    </a>
                                </div>
                            </motion.div>

                            <motion.div
                                initial={{opacity: 0, y: 10}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.3, delay: 0.3}}
                                className="flex items-center space-x-4">
                                <div className="bg-slate-800 p-4 rounded-full">
                                    <FaGithub className="text-blue-400 text-2xl" />
                                </div>
                                <div>
                                    <p className="text-sm text-slate-400">GitHub</p>
                                    <a
                                        href="https://github.com/JarrodSavard"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="text-blue-300 hover:text-blue-200 transition-colors">
                                        github.com/JarrodSavard
                                    </a>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </RevealAnimation>
        </section>
    );
};
