import {AnimatePresence, motion} from "framer-motion";
import React, {useState} from "react";
import {FaChevronDown} from "react-icons/fa";
import {RevealAnimation} from "../../animations/RevealAnimation";

interface FAQItem {
    question: string;
    answer: string;
}

export const FAQSection = () => {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const faqs: FAQItem[] = [
        {
            question: "Are you a US citizen?",
            answer: "Yes, I am a US citizen and eligible to work in the United States without sponsorship requirements.",
        },
        {
            question: "Are you open to remote work?",
            answer: "Yes, I am experienced with remote work environments and have the setup, tools, and self-discipline to be highly effective while working remotely.",
        },
        {
            question: "Are you willing to relocate?",
            answer: "I am open to discussing relocation opportunities for the right position, particularly in tech hubs with vibrant startup ecosystems.",
        },
        {
            question: "What type of roles are you interested in?",
            answer: "I'm primarily focused on founding engineer, technical leadership, and senior engineering roles where I can leverage my experience in architecture, product strategy, and technical implementation.",
        },
        {
            question: "Do you have experience managing teams?",
            answer: "Yes, I have experience leading development teams in startup environments, implementing engineering best practices, and mentoring junior developers.",
        },
    ];

    const toggleFAQ = (index: number) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <section
            id="faq"
            className="w-full px-8 py-20 max-w-7xl mx-auto">
            <RevealAnimation>
                <h2 className="text-4xl font-bold mb-2 text-blue-400">Frequently Asked Questions</h2>
                <div className="h-1 w-20 bg-blue-500 mb-12"></div>

                <div className="grid gap-6 max-w-4xl mx-auto">
                    {faqs.map((faq, index) => (
                        <motion.div
                            key={index}
                            initial={{opacity: 0, y: 20}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.3, delay: index * 0.1}}
                            className="bg-slate-800 border border-slate-700 rounded-lg overflow-hidden">
                            <button
                                className="w-full text-left p-6 flex justify-between items-center focus:outline-none"
                                onClick={() => toggleFAQ(index)}>
                                <h3 className="text-xl font-semibold text-blue-300">{faq.question}</h3>
                                <motion.div
                                    animate={{rotate: activeIndex === index ? 180 : 0}}
                                    transition={{duration: 0.3}}>
                                    <FaChevronDown className="text-blue-400" />
                                </motion.div>
                            </button>
                            <AnimatePresence>
                                {activeIndex === index && (
                                    <motion.div
                                        initial={{height: 0, opacity: 0}}
                                        animate={{height: "auto", opacity: 1}}
                                        exit={{height: 0, opacity: 0}}
                                        transition={{duration: 0.3}}>
                                        <div className="px-6 pb-6">
                                            <p className="text-slate-300">{faq.answer}</p>
                                        </div>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </motion.div>
                    ))}
                </div>
            </RevealAnimation>
        </section>
    );
};
